/* eslint-disable react/no-unsafe */
import React, { Component } from 'react';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';

import { CSSTransitionGroup } from 'react-transition-group';
import { scrollTo } from 'scroll-js';
import { addEventListener } from 'consolidated-events';

import { Modaler } from '@/src/components/Modal';
import ErrorBoundary from '@/src/components/Base/ErrorBoundary';
import * as channelActions from '@/src/actions/channel/channel';
import SearchBoxContainer from '../SearchBoxContainer';
import EventBinder from '@/src/sources/channel/EventBinder';
import { getSwitchesFromGlobal } from '@/src/actions/common';
import { fetchYearWorkingDays } from '@/src/actions/common/workday';

import { initUbt } from '@/src/ubt.v2/channel';
import { COMMON_CONFIGS } from '@/src/constants/channel/commonConfig';
import { getDocumentScrollTop, isRenderComplete } from '@/src/utils/commonUtils';
import store from '@/src/stores/channel';
import LowPriceMapV2 from '../LowPriceMapV2';

// 埋点
import ubt from '@/src/ubt/channelUbt';
import { removeNavBarEvent, addNavBarEvent, hideNavBar, showNavBar } from '@/src/sources/common/NavBarhelper';
import { UBT_KEY } from '@/src/constants/ubtKeys';
import { sendUBT } from '@/src/sources/common/lightUbt';

import AdSliderV2 from '@/src/components/channelv2/AdSliderV2/async';
import ServiceNotice from '@/src/components/channelv2/OtherInfo/async';

import SearchHistoryContainer from '@/src/containers/channelv2/SearchHistoryContainer/async';
import AirlinePreferenceContainer from '@/src/containers/channelv2/AirlinePreferenceContainer/async';
import LowPriceReportContainerV2 from '@/src/containers/channelv2/LowPriceReportContainerV2/async';
import BottomSEOContainer from '@/src/containers/channelv2/BottomSEOContainer/async';
import DomesticSEOContainer from '@/src/containers/channelv2/DomesticSEOContainer/async';

import {
	workingDaysSelector,
	hotDistrictListSelector,
	countryLowestPriceSelector,
	countryLowPriceSelector,
	countryLowestRPriceSelector,
	countryLowRPriceSelector,
	globalSwitchSelector,
	dCitySelector,
	domesticSelector,
	domesticSearchPageSelector,
	smoothAnimationSwitchSelector,
} from '../baseSelector';

// ie polyfill
require('intersection-observer');

const targetTop = 0;

const genListPageUrl = () => {
	const { host, port } = window.location;
	const path = COMMON_CONFIGS.preLoadListPageUrl;
	return `//${host}${port == 80 ? '' : ':' + port}/${path}&__silent__=1`;
};

const appendListPageInIFrame = () => {
	requestAnimationFrame(() => {
		const preListPage = document.getElementById('pre_list_page');
		if (!preListPage) {
			// 如果不存在该 iframe，就添加，以预先下载整个列表页，减少等待时间
			const listPage = document.createElement('iframe');
			listPage.src = genListPageUrl(); // //flights.ctrip.com/international/search/oneway-jkl-hkg?depdate=2020-11-01&cabin=y_s&adult=1&child=0&infant=0&__silent__=1
			listPage.style.width = '0px';
			listPage.style.height = '0px';

			document.body.appendChild(listPage);
		}
	});
};

class ChannelHomeV2 extends Component {
	constructor(props) {
		super(props);
		ubt.registerEvent();
		this.newAdSliderSwitch = getSwitchesFromGlobal().newAdSliderSwitch;
		this.renderComplete = false;
		this.lowPriceFlightAndMapSwitch = getSwitchesFromGlobal().lowPriceFlightAndMapSwitch;
		this.state = {
			showCommitment: false,
			lotteryAlertPopupShow: false,
			lotterFloatShow: false,
			lotteryStatus: '',
			floatLotteryEl: null,
		};
	}

	componentWillMount() {
		EventBinder.init();
		initUbt();
		//应该只有此处读取此全局变量

		let globalSwitches = getSwitchesFromGlobal();

		if (globalSwitches) {
			this.props.actions.genInitGlobalSwitch(globalSwitches);
		}

		this.props.fetchYearWorkingDays();
	}

	componentDidMount() {
		addEventListener(window, 'load', () => {
			if (getDocumentScrollTop() < targetTop) {
				scrollTo(window, {
					top: targetTop,
					duration: 50,
					behavior: 'ease',
				});
			}
		});
		hideNavBar();
		addNavBarEvent();
		sendUBT(UBT_KEY.HOME.SHOW, {}, true);
	}

	componentWillUnmount() {
		if (this.getShowNewAd()) {
			showNavBar();
			removeNavBarEvent();
		}
	}

	componentDidUpdate(prevProps) {
		const prevGlobalSwitch = prevProps.globalSwitch;
		const currentGlobalSwitch = this.props.globalSwitch;

		const prevAppendListPageInChannelSwitch = prevGlobalSwitch.get('appendListPageInChannelSwitch');
		const currentAppendListPageInChannelSwitch = currentGlobalSwitch.get('appendListPageInChannelSwitch');

		if (!prevAppendListPageInChannelSwitch && currentAppendListPageInChannelSwitch) {
			appendListPageInIFrame();
		}

		const prevSmoothAnimationSwitch = prevGlobalSwitch.get('smoothAnimationSwitch');
		const currentSmoothAnimationSwitch = currentGlobalSwitch.get('smoothAnimationSwitch');

		if (!prevSmoothAnimationSwitch && currentSmoothAnimationSwitch) {
			if (getDocumentScrollTop() < targetTop) {
				scrollTo(window, {
					top: targetTop,
					duration: 0,
					behavior: 'ease',
				});
			}
		}
		// 搜索历史模块出现，则认为首页主要流程加载完成，暂不考虑下面内容
		if (window && !this.renderComplete && isRenderComplete('search-history')) {
			window['_nfes_ps_'] = {
				renderEndTime: new Date().getTime(),
			};
			this.renderComplete = true;
		}
	}

	getShowNewAd() {
		return this.newAdSliderSwitch || this.props.domesticSearchPage;
	}

	render() {
		const { smoothAnimationSwitch, dCity } = this.props;

		const onCommitmentClick = () => this.setState({ showCommitment: true });
		const onHideCommitment = () => this.setState({ showCommitment: false });
		const { isDomestic } = this.props;
		const { airlinePreferenceSwitch } = getSwitchesFromGlobal();
		return (
			<div
				onMouseDown={(e) => ubt.onCommonClick(e)}
				className={classnames('channel-body', { 'smooth-v1': smoothAnimationSwitch })}
			>
				<div className="channel-main">
					<div className="wrapper">
						<SearchBoxContainer {...this.props} />

						<SearchHistoryContainer />

						<AdSliderV2 isDomestic={isDomestic} />

						<CSSTransitionGroup
							transitionName="low-price-report-slide-down"
							transitionEnterTimeout={300}
							transitionLeaveTimeout={0}
						>
							<LowPriceReportContainerV2 dCity={dCity} />
						</CSSTransitionGroup>

						<ErrorBoundary>
							<LowPriceMapV2 dCity={dCity} />
						</ErrorBoundary>

						{isDomestic && airlinePreferenceSwitch ? <AirlinePreferenceContainer /> : null}
					</div>
				</div>
				<ServiceNotice
					showCommitment={this.state.showCommitment}
					onCommitmentClick={onCommitmentClick}
					onHideCommitment={onHideCommitment}
				/>

				{isDomestic ? <DomesticSEOContainer /> : <BottomSEOContainer {...this.props} />}
				<Modaler />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		hotDistrictList: hotDistrictListSelector(state),
		workingDays: workingDaysSelector(state),
		countryLowestPrice: countryLowestPriceSelector(state),
		countryLowPrice: countryLowPriceSelector(state),
		countryLowestRPrice: countryLowestRPriceSelector(state),
		countryLowRPrice: countryLowRPriceSelector(state),
		globalSwitch: globalSwitchSelector(state),
		dCity: dCitySelector(state),
		domesticSearchPage: domesticSearchPageSelector(state),
		isDomestic: domesticSelector(state),
		smoothAnimationSwitch: smoothAnimationSwitchSelector(state),
	};
};
const mapDispatchToProps = (dispatch) => ({
	// bindActionCreators方法将actions当作组件的属性方便来调用, channelActions作为flights模块的actions 模块化在一起
	actions: bindActionCreators(channelActions, dispatch),
	// 承上，简单的写法其实可以把组件模块相应的action写在这里，如：
	// fetchFlightList: (req) => {
	//     // dispatch( { type: "REGISTER_FORM_UPDATE", key, value })
	//     return channelActions.fetchFlights(req)
	// }
	fetchYearWorkingDays: () => {
		dispatch(fetchYearWorkingDays());
	},
});

const ChannelHome = connect(mapStateToProps, mapDispatchToProps)(ChannelHomeV2);

export default function ChannelContainer() {
	return (
		<Provider store={store}>
			<ChannelHome />
		</Provider>
	);
}
