import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import queryString from 'qs';
import { sendBlockTraceInHome } from '@/src/utils/getSpecialBlockBasicInfo';
import SearchBox from '../../../components/Base/searchBox/SearchBox';
import { initSearchBoxUbt, SEARCH_BOX_UBT_KEY_TYPES } from '../../../ubt.v2/searchBox';

import {
	getPersistSearchLocalStorage,
	getDomesticgetPersistSearchLocalStorage,
} from '../../../actions/common/searchDataStorageHelper';
import {
	validateSearchBox,
	getListPageUrl,
	getDoemsticSiteListPageUrl,
	getDistrictUrl,
	getHotelUrl,
} from '../../../utils/checkValidate';

import { BlockTypeValue } from '../../../utils/types';
import Tabs from '../../../components/channelv2/tabs';
import { getSearchMapCity } from '../searchMapHelper';
import { UbtHandler } from '../../../ubt.v2/handler';

import { PRE_SEARCH_COST_TIME, HIT_PRE_SEARCH } from '../../../sources/common/localStorageKey';

import { appendSignToUrl, concatUrlQuery } from '../../../utils/appendSignToUrl';
import { getPreAggSearchIdPromise } from '../../../sources/channel/getSearchId';
import {
	getPreSearchCacheByUrl,
	searchButtonInPreSearchPathConfig,
	getPreSearchCachedTotalCount,
	uploadMatchedPreSearchPathUbt,
	forceGetPreSearchId,
} from '../../../sources/channel/preSearch';
import { getIfSwitchOpenAsync } from '../../../sources/common/getIfSwitchOpenAsync';
// import { trimUrlHost } from '../../sources/common/trimUrlHost';
import Announcement from '../../../components/Base/announcement';
import { updatePageIdAndPagePv } from '../../../sources/common/updatePageId';
import { sendUBT } from '../../../sources/common/lightUbt/index';
import { UBT_KEY } from '../../../constants/ubtKeys';
import { mapStateToProps, mapDispatchToProps, ubtSearchBox } from './mapToProps';

const searchButtonPreSearchConfigName = 'presearch_inquire';

// eslint-disable-next-line react/no-unsafe
class SearchBoxContainer extends React.Component<any, any> {
	hasUploadUbtSearchData = false;
	searchBoxListerners: any[] = [];
	persistData: any = null;

	constructor(props) {
		super(props);
		this.state = {
			showServiceContent: false,
			searchHotel: false,
			showMultiFlightAlertPopup: false,
		};
		this.onShowServiceContent = this.onShowServiceContent.bind(this);
		this.onSearchFormSubmit = this.onSearchFormSubmit.bind(this);
		this.setSearchHotel = this.setSearchHotel.bind(this);
		this.changePoi = this.changePoi.bind(this);
		this.clickHotDistrict = this.clickHotDistrict.bind(this);
		this.setShowMultiFlightAlertPopup = this.setShowMultiFlightAlertPopup.bind(this);
		this.sendBlockTrace = this.sendBlockTrace.bind(this);
	}

	componentWillMount() {
		const { domesticSearchSwitch } = window.GlobalSwitches || {};
		initSearchBoxUbt();
		const persistData = getPersistSearchLocalStorage();
		const domesticPersistData = getDomesticgetPersistSearchLocalStorage();
		if (domesticSearchSwitch) {
			// 比较下存储日期 哪个最新更新哪个
			if (persistData && domesticPersistData && persistData.date && domesticPersistData.date) {
				this.persistData = persistData.date > domesticPersistData.date ? persistData : domesticPersistData;
			} else if (persistData && !domesticPersistData) {
				this.persistData = persistData;
			} else if (!persistData && domesticPersistData) {
				this.persistData = domesticPersistData;
			} else if (persistData && domesticPersistData && !persistData.date && domesticPersistData.date) {
				this.persistData = domesticPersistData;
			} else {
				this.persistData = persistData;
			}
		} else {
			this.persistData = persistData;
		}

		this.props.cacheRecovery(this.persistData);
		// const useNewPoiSwitcher = window.GlobalSwitches.useNewPoiSwitcher;
		// !useNewPoiSwitcher && this.props.fetchHotDistricts();
		// !useNewPoiSwitcher && this.props.fetchDomesticCityData();
		// 设置出发地，到达地默认值
		if (!this.persistData) {
			this.props.fetchUserLocation();
			SEARCH_BOX_UBT_KEY_TYPES.SEARCH_BOX_INIT.value.process({ noneValue: 1 });
		}
		//设置链接上带有城市code回填到搜索框上
		const queryParam = queryString.parse(location.search && location.search[0] === '?' ? location.search.substring(1) : location.search),
			departureCode = queryParam && queryParam.departure && queryParam.departure.toUpperCase();
		if (departureCode) {
			this.props.fetchSearchLinkData(departureCode);
			SEARCH_BOX_UBT_KEY_TYPES.SEARCH_BOX_INIT.value.process({ SEOValue: queryParam });
		}
	}

	componentDidUpdate() {
		if (!this.hasUploadUbtSearchData) {
			this.hasUploadUbtSearchData = true;
			const { flightWay, selectedCity, owDDate, rtADate, arrivalDistrict } = this.props;
			SEARCH_BOX_UBT_KEY_TYPES.SEARCH_BOX_INIT.value.process({
				flightWay: flightWay,
				selectedCity: selectedCity,
				owDDate: owDDate,
				rtADate: rtADate,
				arrivalDistrict: arrivalDistrict,
			});
		}
	}

	componentWillUpdate(nextProps, nextState) {
		const {
			owDDate,
			rtADate,
			flightWay,
			selectedCity,
			onChangeOwEndDate,
			onChangeADateError,
			onSynSearchBoxDataToSearchMap,
		} = this.props;

		const dCityCode = selectedCity.get('owDCity') && selectedCity.get('owDCity').get('Code');

		const nextDCity = nextProps.selectedCity && nextProps.selectedCity.get('owDCity'),
			nextDCityCode = nextDCity && nextDCity.get('Code'),
			nextDDate = nextProps.owDDate,
			nextADate = nextProps.rtADate,
			nextFlightWay = nextProps.flightWay;
		if (
			(dCityCode != nextDCityCode && nextDCityCode) ||
			(moment(owDDate).format('YYYY-MM-DD') != moment(nextDDate).format('YYYY-MM-DD') && nextDDate) ||
			(moment(rtADate).format('YYYY-MM-DD') != moment(nextADate).format('YYYY-MM-DD') && nextADate)
		) {
			onSynSearchBoxDataToSearchMap({
				dDate: moment(nextDDate),
				aDate: moment(nextADate),
				flightWay: nextFlightWay,
				dCity: getSearchMapCity(nextDCity && nextDCity.toJS()),
			});
		}

		// 切换到返程，未选择返程日期，并且返程日历关闭的情况
		if (nextProps.flightWay == 'RT') {
			// 切换到返程，未选择返程日期，并且返程日历关闭的情况
			if (
				flightWay == 'OW' &&
				(!nextProps.rtADate ||
					!nextProps.rtADate.isValid() ||
					moment(nextProps.owDDate).isAfter(nextProps.rtADate))
			) {
				// 切换单程往返的情况下，如果owDDate晚于aDate，自动给aDate加三天
				onChangeOwEndDate({
					date: moment(owDDate).add(3, 'days').format('YYYY-MM-DD'),
				});
			}
		}
	}

	componentDidMount() {
		this.setDefaultADate();
	}

	setDefaultADate() {
		if (!this.persistData) {
			const { defaultSearchData = {} } = window.GlobalConfigs || {};
			const aCity = this.props.domestic ? defaultSearchData.domesticACity : defaultSearchData.aCity;
			this.props.setPoi('owACity', aCity, 0, false);
		}
	}

	changePoi(name, item, id, focusNextInput) {
		this.props.setPoi(name, item, id, focusNextInput);
		if (name == 'owDCity' && item && !item.updateTimeZone) {
			ubtSearchBox('presearch_depart_city', this);
		} else if (name == 'owACity' && item && !item.updateTimeZone) {
			ubtSearchBox('presearch_arrive_city', this);
		}
	}

	clickHotDistrict(district) {
		this.props.setArrivalDistrict(district);
	}

	onShowServiceContent(show) {
		this.setState({
			showServiceContent: show,
		});
	}

	setSearchHotel(search) {
		this.setState({
			searchHotel: search,
		});
		SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK.value.process({
			tag: {
				type: 'searchHotel',
				text: '搜索酒店',
				value: search,
			},
		});
	}

	sendBlockTrace(subType: string) {
		const {
			flightWay,
			selectedCity,
			domestic,
			passenger,
			classGrade
		} = this?.props || {};
		let { adtCount, chiCount, infCount } = passenger || {adtCount:1, chiCount:0, infCount:0};
		const { owDCity, owACity } = selectedCity || {};
		sendBlockTraceInHome({
			domestic,
			adtCount,
			chiCount,
			infCount,
			flightWay,
			cabinType: classGrade,
			blockType: BlockTypeValue.Focus,
			subType,
			dCityText: owDCity?.Name,
			aCityText: owACity?.Name,
		});
	}
	// 搜索框输入是否有效
	checkIfSearchIsValid(opts = { notifyOnError: true }) {
		const {
			canNotSearch,
			isDistrictSearch,
			owDDate,
			rtDDate,
			rtADate,
			flightWay,
			selectedCity,
			getMtSegList,
			domestic,
		} = this.props;
		let {
			focusMtSecondDCity,
			openHotCityPicker,
			onChangeMtDCityError,
			onChangeMtACityError,
			onChangeMtDDateError,
			onChangeDDateError,
			onChangeADateError,
			onChangeDCityError,
			onChangeACityError,
		} = this.props;

		const dCityCode = selectedCity?.get('owDCity')?.get('ParentCode')
				? selectedCity?.get('owDCity')?.get('ParentCode')
				: selectedCity?.get('owDCity')?.get('Code'),
			aCityCode = selectedCity?.get('owACity')?.get('ParentCode')
				? selectedCity?.get('owACity')?.get('ParentCode')
				: selectedCity?.get('owACity')?.get('Code');

		if (flightWay == 'OW' && dCityCode === aCityCode) {
			this.props.fetchFlightSameCityDetectData('onlineFlight', dCityCode);
		}

		if (opts && !opts.notifyOnError) {
			const nope = () => {};

			focusMtSecondDCity = nope;
			openHotCityPicker = nope;
			onChangeMtDCityError = nope;
			onChangeMtACityError = nope;
			onChangeMtDDateError = nope;
			onChangeDDateError = nope;
			onChangeADateError = nope;
			onChangeDCityError = nope;
			onChangeACityError = nope;
		}
		const { flightSameCityData } = this.props;

		const mtSegList = getMtSegList();

		const { mainIsValid } = validateSearchBox({
			canNotSearch,
			isDistrictSearch,
			selectedCity,
			flightWay,
			owDDate,
			rtDDate,
			rtADate,
			mtSegList,
			focusMtSecondDCity,
			openHotCityPicker,
			onChangeMtDCityError,
			onChangeMtACityError,
			onChangeMtDDateError,
			onChangeDDateError,
			onChangeADateError,
			onChangeDCityError,
			onChangeACityError,
			domestic,
			setShowMultiFlightAlertPopup: this.setShowMultiFlightAlertPopup,
			flightSameCityData,
			sendBlockTrace: opts?.notifyOnError ? this.sendBlockTrace  : (subType: string)=>{ console.log(subType); }, // 鼠标hover在搜索按钮的时候不发阻塞埋点,不然埋点会发好几遍
		});

		return mainIsValid;
	}

	// 生成跳转列表页的URL
	getRedirectUrl() {
		const {
			isDistrictSearch,
			owDDate,
			rtDDate,
			flightWay,
			selectedCity,
			arrivalDistrict,
			classGrade,
			getMtSegList,
			directflight,
			domestic,
			changeTime,
			passenger,
		} = this.props;
		let { rtADate } = this.props;
		rtADate =
			flightWay == 'RT' &&
			(moment(rtADate).isBefore(rtDDate) || rtADate == null || (rtADate && !rtADate.isValid()))
				? moment(rtDDate).add(3, 'days')
				: rtADate;
		const mtSegList = getMtSegList();
		const searchHotel = this.state.searchHotel;
		let url = '';
		let openBlankWindow = false;
		let opendNewTab = false;
		if (flightWay != 'MT' && isDistrictSearch) {
			url = getDistrictUrl({ owDDate, rtADate, flightWay, selectedCity, arrivalDistrict, rtDDate, directflight });
			opendNewTab = true;
		} else if (searchHotel && domestic) {
			openBlankWindow = true;
			url = getHotelUrl({
				owDDate,
				rtADate,
				flightWay,
				selectedCity,
				mtSegList,
			});
		} else {
			if (domestic) {
				url = getDoemsticSiteListPageUrl({
					owDDate,
					rtADate,
					flightWay,
					selectedCity,
					mtSegList,
					passenger,
					classGrade,
					changeTime,
				});
			} else {
				url = getListPageUrl({
					owDDate,
					rtADate,
					flightWay,
					selectedCity,
					mtSegList,
					rtDDate,
					passenger,
					classGrade,
					directflight,
				});
			}
		}

		return { url, openBlankWindow, opendNewTab };
	}

	// 点击搜索按钮
	onSearchFormSubmit(event) {
		sessionStorage.removeItem(PRE_SEARCH_COST_TIME);

		event.preventDefault();
		ubtSearchBox(searchButtonPreSearchConfigName, null);
		const { onCleanOwRtError, onCleanMtError } = this.props;

		onCleanOwRtError();
		onCleanMtError();

		const mainIsValid = this.checkIfSearchIsValid();
		if (!mainIsValid) {
			return;
		}

		const { domestic, domesticSearchPage, changeChannelPageIdSwitch, searchBoxOnlyDomestic } = this.props;
		const { url: redirectUrl, openBlankWindow, opendNewTab } = this.getRedirectUrl();

		// 国内酒店，直接打开窗口
		if (openBlankWindow) {
			window.open(redirectUrl);
			SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK.value.process({
				tag: {
					type: 'locationToHotle',
					text: '搜索酒店',
					value: redirectUrl,
				},
			});
			return;
		}
		if (opendNewTab) {
			window.location.href = redirectUrl;
			SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK.value.process({
				tag: {
					type: 'districtSearch',
					text: '模糊搜索',
					value: redirectUrl,
				},
			});
			return;
		}

		if (changeChannelPageIdSwitch) {
			if (!domestic && domesticSearchPage) {
				updatePageIdAndPagePv('10320669438');
			} else if (domestic && !domesticSearchPage) {
				updatePageIdAndPagePv('101023');
			}
		}

		sendUBT(UBT_KEY.HOME.HOME_BUTTON_PRESS_SUCCESS, { isInternational: !searchBoxOnlyDomestic }, true);

		const doRedirect = (url, needSign) => {
			// todo 此处先屏蔽掉 sign 模式，待首页搜索框预搜索完成后再打开
			needSign = false;
			if (!searchBoxOnlyDomestic && this.props.appendSignToListPageSwitch && needSign) {
				const originalUrl = url;
				try {
					url = appendSignToUrl(url) || originalUrl;
				} catch (error) {
					url = originalUrl;
					console.error(error);
				}
			}

			window.location.href = url;

			SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK.value.process({
				tag: {
					type: 'search',
					text: '搜索',
					value: url,
				},
			});
		};

		sessionStorage.setItem(PRE_SEARCH_COST_TIME, JSON.stringify({ s0: +new Date() }));
		const { noNeedSearchId } = window.GlobalSwitches || {};

		if (!searchBoxOnlyDomestic && this.props.homePreAggSearchSwitch && !noNeedSearchId) {
			// 预搜索AGG，如果此前有触发查询条件拿到过 searchId且未超时，就直接用，否则要临时调用
			const preSearchCache = getPreSearchCacheByUrl(redirectUrl, true);

			// 可能是mouseover搜索按钮就触发的getSearchId请求还没拿到响应，此处返回的cache就是该请求的promise
			const preSearchPromise =
				preSearchCache &&
				typeof preSearchCache.toString === 'function' &&
				preSearchCache.toString().indexOf('promise') > 0
					? preSearchCache
					: null;

			if (preSearchPromise) {
				UbtHandler.onUploadData('pre_search_of_mouse_over_search_button_in_progress', url);
			}

			let preSearchSearchButton = false; // 如果没有命中预搜索路径配置，但搜索按钮又在配置路径里面，此处点击搜索按钮也会即时获取 searchId
			const uploadHitPreSearchUbt = (hit, path, count) => {
				UbtHandler.onUploadData('c_home_pre_search_hit_stat', { hit, path, totalCount: count });
			};

			if (preSearchCache) {
				uploadHitPreSearchUbt(true, preSearchCache.path, getPreSearchCachedTotalCount());
			}

			const getSearchIdPromise =
				preSearchPromise ||
				(preSearchCache && preSearchCache.searchId
					? Promise.resolve(preSearchCache.searchId)
					: new Promise((resolve, _reject) => {
							if (searchButtonInPreSearchPathConfig) {
								getIfSwitchOpenAsync('channelSearchBoxPreSearchSwitch').then((opened) => {
									if (opened) {
										preSearchSearchButton = true;
										uploadHitPreSearchUbt(
											true,
											[searchButtonPreSearchConfigName],
											1 + getPreSearchCachedTotalCount()
										);
										getPreAggSearchIdPromise(redirectUrl).then((searchId) => resolve(searchId));
										uploadMatchedPreSearchPathUbt([searchButtonPreSearchConfigName]);
									} else {
										uploadHitPreSearchUbt(false, [], getPreSearchCachedTotalCount());
										resolve('');
									}
								});
							} else {
								uploadHitPreSearchUbt(false, [], getPreSearchCachedTotalCount());
								UbtHandler.onUploadData('c_home_pre_search_inquire_not_in_config', {
									remark: '首页点击搜索按钮，预搜索没有匹配到任何路径，且搜索按钮路径 presearch_inquire 没有在配置内',
								});
								resolve('');
							}
					  }));

			getSearchIdPromise.then((searchId) => {
				if (searchId) {
					const urlWithAggSearchId = concatUrlQuery(redirectUrl, 'searchid', searchId);
					if (preSearchCache || preSearchSearchButton) {
						sessionStorage.setItem(HIT_PRE_SEARCH, '1');
					}

					doRedirect(urlWithAggSearchId, false);
				} else {
					doRedirect(redirectUrl, true);
				}
			});
		} else {
			doRedirect(redirectUrl, true);
		}
	}

	setShowMultiFlightAlertPopup = (show) => {
		this.setState({
			showMultiFlightAlertPopup: show,
		});
	};

	onMouseOverSearchButton = () => {
		const { noNeedSearchId } = window.GlobalSwitches || {};
		const { searchBoxOnlyDomestic } = this.props;
		if (searchBoxOnlyDomestic) {
			UbtHandler.onUploadData('no_presearch_because_domestic', {});
			return;
		}

		const mainIsValid = this.checkIfSearchIsValid({ notifyOnError: false });
		if (!mainIsValid) {
			UbtHandler.onUploadData('no_presearch_because_invalid', {});
			return;
		}

		// 下线getSearchId接口，不再获取searchId
		if (noNeedSearchId) {
			return;
		}

		const { url } = this.getRedirectUrl();
		const preSearchCache = getPreSearchCacheByUrl(url, false);
		if (!preSearchCache) {
			// 没有缓存，此时hover搜索按钮就触发
			forceGetPreSearchId(url);
		} else {
			UbtHandler.onUploadData('already_presearch_on_mouse_over_search_btn', {});
		}
		sendUBT(UBT_KEY.HOME.SEARCH_BUTTON_PRESS, {}, true);
	};

	render() {
		const {
			selectFlightWay,
			onChangeOwStartDate,
			onChangeOwEndDate,
			onChangeRtStartDate,
			onChangeRtEndDate,
			exchangeCity,
		} = this.props;
		const { showServiceContent, showMultiFlightAlertPopup, searchHotel } = this.state;

		return (
			<Tabs showServiceContent={showServiceContent} onShowServiceContent={this.onShowServiceContent}>
				<Announcement />
				<SearchBox
					{...this.props}
					onSearchFormSubmit={this.onSearchFormSubmit}
					showSubmitBtn={true}
					autoFocus={true}
					showDirectFlight={true}
					changePoi={this.changePoi}
					setSearchHotel={this.setSearchHotel}
					searchHotel={searchHotel}
					clickHotDistrict={this.clickHotDistrict}
					selectFlightWay={selectFlightWay}
					onChangeOwStartDate={onChangeOwStartDate}
					onChangeOwEndDate={onChangeOwEndDate}
					onChangeRtStartDate={onChangeRtStartDate}
					onChangeRtEndDate={onChangeRtEndDate}
					exchangeCity={exchangeCity}
					onMouseOverSearchButton={this.onMouseOverSearchButton}
					showMultiFlightAlertPopup={showMultiFlightAlertPopup}
					setShowMultiFlightAlertPopup={this.setShowMultiFlightAlertPopup}
				/>
			</Tabs>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBoxContainer);
