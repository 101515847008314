import mergeConfig from '../common/mergeConfig'
import Q_CONFIG_KEYS from './qConfigKeys'

const CONSTS = [
    {
        "name": "channelAnnouncement",
        "switchKey": "channelAnnouncementSwitch",
        "content": "新冠疫情期间，各国出入境政策变化频繁。携程全力跟进更新中。",
        "detailLink": "",
        "displayVersion": ""
    }
]

export default mergeConfig(Q_CONFIG_KEYS.ANNOUNCEMENTS, CONSTS)
