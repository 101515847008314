import dynamic from 'next/dynamic';
import { ubtError } from '@/src/sources/common/lightUbt';

export default dynamic(() => import(/* webpackChunkName: "channel.serviceNotice" */ './OtherInfo'), {
	ssr: false,
	loading: ({ error }) => {
		if (error) {
			ubtError(error);
		}
		return null;
	},
});
