// 加一个标识，控制导航过快隐藏
let navBarShouldHide = false
const showNavBar = () => {
    navBarShouldHide = false
    const els = getNavBarEls()
    const { navFlight } = els
    setTimeout(()=>{
        !navBarShouldHide && navFlight && navFlight.classList.add("cui_nav_current")

    }, 550)

}
const hideNavBar = () => {
    const {navFlight, nav } = getNavBarEls()
    navBarShouldHide = true
    setTimeout(()=>{
        navBarShouldHide && navFlight && navFlight.classList.remove("cui_nav_current")
        if(nav) nav.style.backgroundColor = '#0a56bb'
    }, 500)
}


const getNavBarEls = () => {
    let nav = document.querySelector('#nav_flight')
    let point = nav && nav.querySelector('.point')
    let commonNav = document.querySelector('#ul_nav_flight')
    let nav_flight = document.querySelector('#cui_nav_flight')
    let orderBtn = nav_flight && nav_flight.querySelector('.cui_ico_order')

    return {
        commonNav, orderBtn, point, nav, navFlight: nav_flight
    }
}
const removeNavBarEvent = () => {
    try {
        const navEls = getNavBarEls()
        const { navFlight } = navEls

        if (navFlight) {
            navFlight.removeEventListener('mouseout', hideNavBar)
            navFlight.removeEventListener('mouseover', showNavBar)
        }
    } catch (error) {
        console.error(error)
    }
}

const addNavBarEvent = () => {
    try {
        const navEls = getNavBarEls()
        const {navFlight} = navEls

        if (navFlight) {
            navFlight.addEventListener('mouseover', showNavBar)
            navFlight.addEventListener('mouseout', hideNavBar)
        }
    } catch (err) {
        console.error(err)
    }
}
export {
    removeNavBarEvent,
    addNavBarEvent,
    hideNavBar,
    showNavBar
}
