import React from 'react';
import { UbtBoundary } from '../../ubt.v2/boundary';
import { ChannelUbtGenerator } from '../../ubt.v2/channel/handler';
import { CHANNEL_UBT_GROUP_TYPES } from '../../ubt.v2/channel/groupTypes';
import { CHANNEL_UBT_KEY_TYPES } from '../../ubt.v2/channel/keyTypes';
import { getNewFuzzyPage } from '../../utils/checkValidate';

const Tabs = ({ ...props }) => {
	const { showServiceContent, onShowServiceContent } = props;
	const useNewPoiSwitcher = window.GlobalSwitches.teamBookingSwitcher;
	return (
		<div className="search-box-tabs">
			<ul className="nav-tabs clearfix">
				<UbtBoundary
					tracker={ChannelUbtGenerator(
						CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
						CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
						{
							type: 'searchTab',
							text: '机票搜索',
						}
					)}
				>
					<li
						onClick={() => {
							onShowServiceContent(false);
						}}
						className={`${showServiceContent ? '' : 'active'} tab-flight-search`}
					>
						{/* <i className="iconf-tab-selected">&#xe60d;</i>
                    <i className="iconf-tab-normal">&#xe64e;</i>
                    <i className="iconf-tab-hover">&#xe64e;</i> */}
						国内、国际/中国港澳台
					</li>
				</UbtBoundary>
				<UbtBoundary
					tracker={ChannelUbtGenerator(
						CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
						CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
						{
							type: 'searchTab',
							text: '特价机票',
						}
					)}
				>
					<li>
						<a
							rel="noopener noreferrer"
							href={`${getNewFuzzyPage(
								encodeURIComponent('https://flights.ctrip.com/fuzzy/#ctm_ref=fli_hp_sb_findlprice_t'),
								'channel=online_budget'
							)}`}
							target="_self"
						>
							特价机票
						</a>
					</li>
				</UbtBoundary>
				<UbtBoundary
					tracker={ChannelUbtGenerator(
						CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
						CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
						{
							type: 'searchTab',
							text: '航班动态',
						}
					)}
				>
					<li>
						<a rel="noopener noreferrer" href="https://flights.ctrip.com/actualtime" target="_self">
							{/* <i className="iconf-tab-selected">&#xe605;</i>
                        <i className="iconf-tab-normal">&#xe605;</i>
                        <i className="iconf-tab-hover">&#xe605;</i> */}
							航班动态
						</a>
					</li>
				</UbtBoundary>

				<UbtBoundary
					tracker={ChannelUbtGenerator(
						CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
						CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
						{
							type: 'searchTab',
							text: '在线选座',
						}
					)}
				>
					<li>
						<a
							rel="noopener noreferrer"
							href="https://flights.ctrip.com/online/orderdetail/iframepage?h5PageName=Bookseat&isHideNavBar=YES&autoJumpNotCtriper=true&origin=61"
							target="_self"
						>
							在线选座
						</a>
					</li>
				</UbtBoundary>

				<UbtBoundary
					tracker={ChannelUbtGenerator(
						CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
						CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
						{
							type: 'searchTab',
							text: '退票改签',
						}
					)}
				>
					<li>
						<a
							rel="noopener noreferrer"
							href="https://my.ctrip.com/Home/Order/FlightOrderList.aspx#ctm_ref=fli_hp_tool_def_i_1"
							target="_self"
						>
							{/* <i className="iconf-tab-selected">&#xd60f;</i>
                        <i className="iconf-tab-normal">&#xd60f;</i>
                        <i className="iconf-tab-hover">&#xd60f;</i> */}
							退票改签
						</a>
					</li>
				</UbtBoundary>

				<UbtBoundary
					tracker={ChannelUbtGenerator(
						CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
						CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
						{
							type: 'searchTab',
							text: '更多服务',
						}
					)}
				>
					<li
						onClick={() => {
							onShowServiceContent(true);
						}}
						className={`${showServiceContent ? 'active' : ''}`}
					>
						{/* <i className="iconf-tab-selected">&#xd60e;</i>
                    <i className="iconf-tab-normal">&#xd613;</i>
                    <i className="iconf-tab-hover">&#xd613;</i> */}
						更多服务
					</li>
				</UbtBoundary>
			</ul>
			<div className={`tab-content ${showServiceContent ? 'tab-content-service' : ''}`}>
				{showServiceContent ? (
					<div>
						<ul className="services-con">
							<li>
								<UbtBoundary
									tracker={ChannelUbtGenerator(
										CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
										CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
										{
											type: 'moreService',
											text: '报销凭证',
										}
									)}
								>
									<a
										href="//flights.ctrip.com/webapp/nfesdelivery/delivery/tab.html?oid=0"
										rel="noopener noreferrer"
										target="_blank"
									>
										<i className="services-icon-2"></i>
										<div>报销凭证</div>
									</a>
								</UbtBoundary>
							</li>
							<li>
								<UbtBoundary
									tracker={ChannelUbtGenerator(
										CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
										CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
										{
											type: 'moreService',
											text: '机场攻略',
										}
									)}
								>
									<a
										href="//flights.ctrip.com/booking/airport-guides.html"
										rel="noopener noreferrer"
										target="_self"
									>
										<i className="services-icon-1"></i>
										<div>机场攻略</div>
									</a>
								</UbtBoundary>
							</li>
							<li>
								<UbtBoundary
									tracker={ChannelUbtGenerator(
										CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
										CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
										{
											type: 'moreService',
											text: '国内机场大全',
										}
									)}
								>
									<a
										href="//flights.ctrip.com/hot-airport.html"
										rel="noopener noreferrer"
										target="_blank"
									>
										<i className="services-icon-3"></i>
										<div>国内机场大全</div>
									</a>
								</UbtBoundary>
							</li>
							<li>
								<UbtBoundary
									tracker={ChannelUbtGenerator(
										CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
										CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
										{
											type: 'moreService',
											text: '国际机场大全',
										}
									)}
								>
									<a
										href="//flights.ctrip.com/international/search/hot-airports.html"
										rel="noopener noreferrer"
										target="_blank"
									>
										<i className="services-icon-4"></i>
										<div>国际机场大全</div>
									</a>
								</UbtBoundary>
							</li>
							<li>
								<UbtBoundary
									tracker={ChannelUbtGenerator(
										CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
										CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
										{
											type: 'moreService',
											text: '定制包机',
										}
									)}
								>
									<a
										href="//flights.ctrip.com/flight/charter/book"
										rel="noopener noreferrer"
										target="_self"
									>
										<i className="services-icon-5"></i>
										<div>定制包机</div>
									</a>
								</UbtBoundary>
							</li>
							{useNewPoiSwitcher && (
								<li>
									<UbtBoundary
										tracker={ChannelUbtGenerator(
											CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
											CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
											{
												type: 'teamBooking',
												text: '团体票',
											}
										)}
									>
										<a
											href="//m.ctrip.com/webapp/flight/postservice/iframes/index.html?type=teamBooking&width=375&height=650&channel=pc"
											rel="noopener noreferrer"
											target="_self"
										>
											<img
												src="https://pages.c-ctrip.com/flight_h5/tinyapp/teamBooking.png"
												className="services-icon-5"
											></img>
											<div>团体票</div>
										</a>
									</UbtBoundary>
								</li>
							)}
						</ul>
						<div className="app-download">
							<div className="qrcode"></div>
							扫码下载携程APP
						</div>
					</div>
				) : (
					props.children
				)}
			</div>
		</div>
	);
};

export default Tabs;
