import { createSelector } from 'reselect';
import { isDomesticFlight } from '../../sources/common/isDomesticFlight';
import getMtSegList from '../../reducers/channel/getMtSegList';

export const currentFlightWaySelector = (state) => state.getIn(['channel', 'search', 'currentFlightWay']);
export const owStartDateSelector = (state) => state.getIn(['channel', 'search', 'owDDate']);
export const owEndDateSelector = (state) => state.getIn(['channel', 'search', 'owADate']);
export const rtStartDateSelector = (state) => state.getIn(['channel', 'search', 'rtDDate']);
export const rtEndDateSelector = (state) => state.getIn(['channel', 'search', 'rtEndDate']);
export const adtCountSelector = (state) => state.getIn(['channel', 'search', 'adtCount']);
export const chiCountSelector = (state) => state.getIn(['channel', 'search', 'chiCount']);
export const infCountSelector = (state) => state.getIn(['channel', 'search', 'infCount']);
export const selectedCitySelector = (state) => state.getIn(['channel', 'poi', 'selectedCity']);
export const focusReturnDateSelector = (state) => state.getIn(['channel', 'search', 'focusReturnDate']);
export const isShowInfantTipsSelector = (state) => state.getIn(['channel', 'search', 'isShowInfantTips']);
export const isShowChildTipsSelector = (state) => state.getIn(['channel', 'search', 'isShowChildTips']);
export const domesticClassGradeSelector = (state) => state.getIn(['channel', 'search', 'domesticClassGrade']);
export const dDateErrorSelector = (state) => state.getIn(['channel', 'search', 'dDateError']);
export const aDateErrorSelector = (state) => state.getIn(['channel', 'search', 'aDateError']);
export const dCityErrorSelector = (state) => state.getIn(['channel', 'search', 'dCityError']);
export const aCityErrorSelector = (state) => state.getIn(['channel', 'search', 'aCityError']);
export const showValidateErrorTipSelector = (state) => state.getIn(['channel', 'search', 'showValidateErrorTip']);
export const isNeedFocusSecondSelector = (state) => state.getIn(['channel', 'search', 'isNeedFocusSecond']);
export const canNotSearchSelector = (state) => state.getIn(['channel', 'search', 'canNotSearch']);
export const showCityPickerSelector = (state) => state.getIn(['channel', 'search', 'showCityPicker']);
export const focusNextInputSelector = (state) => state.getIn(['channel', 'search', 'focusNextInput']);
export const directflightSelector = (state) => state.getIn(['channel', 'search', 'directflight']);
export const changeTimeSelector = (state) => state.getIn(['channel', 'search', 'changeTime']);
export const flightSameCityDataSelector = (state) => state.getIn(['channel', 'search', 'flightSameCityData']);

export const lowPriceSelector = (state) => state.getIn(['channel', 'calendar', 'lowPrice']);
export const lowRPriceSelector = (state) => state.getIn(['channel', 'calendar', 'lowRPrice']);
export const lowestPriceSelector = (state) => state.getIn(['channel', 'calendar', 'lowestPrice']);
export const lowestRPriceSelector = (state) => state.getIn(['channel', 'calendar', 'lowestRPrice']);

export const countryLowestPriceSelector = (state) => state.getIn(['channel', 'calendar', 'countryLowestPrice']).toJS();
export const countryLowPriceSelector = (state) => state.getIn(['channel', 'calendar', 'countryLowPrice']).toJS();
export const countryLowestRPriceSelector = (state) =>
	state.getIn(['channel', 'calendar', 'countryLowestRPrice']).toJS();
export const countryLowRPriceSelector = (state) => state.getIn(['channel', 'calendar', 'countryLowRPrice']).toJS();

export const isDistrictSearchSelector = (state) => state.getIn(['channel', 'hotDistrict', 'isDistrictSearch']);
export const arrivalDistrictSelector = (state) => state.getIn(['channel', 'hotDistrict', 'arrivalDistrict']);
export const workingDaysSelector = (state) => state.getIn(['channel', 'common', 'workingDays']);
export const hotDistrictListSelector = (state) => state.getIn(['channel', 'hotDistrict', 'hotDistrictList']);

export const getMtSegListSelector = (state) => () => {
	return getMtSegList(state.getIn(['channel', 'poi', 'selectedCity']));
};
export const mtErrorSelector = (state) => state.getIn(['channel', 'search', 'mtError']);
export const canAddMoreMtSegSelector = (state) => state.getIn(['channel', 'search', 'canAddMoreMtSeg']);
export const priceMapSwitchSelector = (state) => state.getIn(['channel', 'globalSwitch', 'priceMapSwitch']);
export const globalSwitchSelector = (state) => state.getIn(['channel', 'globalSwitch']);
export const dCitySelector = (state) => state.getIn(['channel', 'poi', 'selectedCity', 'owDCity']);

export const searchSelector = (state) => state.getIn(['channel', 'search']);

export const domesticSearchPageSelector = (state) => state.getIn(['channel', 'domesticSearchPage']);

// 搜索框中的城市/地区是否都是中国大陆（涉及中国港澳台就属于国际航线）
export const searchBoxOnlyDomesticSelector = createSelector(
	[selectedCitySelector, getMtSegListSelector, currentFlightWaySelector, domesticSearchPageSelector],
	(selectedCity, getMtSegList, flightWay, domesticSearchPage) => {
		const mtSegList = getMtSegList();
		return isDomesticFlight(selectedCity, mtSegList, flightWay, domesticSearchPage);
	}
);

// 首页适配国内开关打开，且搜索框中的城市/地区是否都是中国大陆（涉及中国港澳台就属于国际航线）
export const domesticSelector = createSelector(
	[globalSwitchSelector, searchBoxOnlyDomesticSelector],
	(globalSwitch, searchBoxOnlyDomestic) => {
		if (!globalSwitch.get('domesticSearchSwitch')) {
			return false;
		} else {
			return searchBoxOnlyDomestic;
		}
	}
);

export const displayClassGradeSelector = createSelector([searchSelector, domesticSelector], (search, domestic) => {
	return domestic ? search.get('dClassGrade') : search.get('iClassGrade');
});

export const displayPassengerSelector = createSelector([searchSelector, domesticSelector], (search, domestic) => {
	return domestic ? search.get('dPassenger').toJS() : search.get('iPassenger').toJS();
});

export const getIfAppendSignToListPageSwitchSelector = createSelector([globalSwitchSelector], (globalSwitch) =>
	globalSwitch.get('appendSignToListPageSwitch')
);

export const getIfHomePreAggSearchSwitchSelector = createSelector([globalSwitchSelector], (globalSwitch) =>
	globalSwitch.get('homePreAggSearchSwitch')
);

export const smoothAnimationSwitchSelector = createSelector([globalSwitchSelector], (globalSwitch) =>
	globalSwitch.get('smoothAnimationSwitch')
);
export const changeChannelPageIdSwitchSelector = createSelector([globalSwitchSelector], (globalSwitch) =>
	globalSwitch.get('changeChannelPageIdSwitch')
);
