import React from 'react';
import dynamic from 'next/dynamic';
import { ubtError } from '@/src/sources/common/lightUbt';

export default dynamic(() => import(/* webpackChunkName: "channel.adSliderV2" */ './AdSliderV2'), {
	ssr: false,
	loading: ({ error }) => {
		if (error) {
			ubtError(error);
		}
		return <div className="ad-loading"></div>;
	},
});
