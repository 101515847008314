import React, { useEffect } from 'react';
import LowPriceMap from '@ctrip/flt-smart-component-pc/lowPriceMap';
import { logTraceByKeyName } from '@ctrip/xproduct-library-h5/lib/Tracking';
// import '@ctrip/flt-smart-component-pc/lowPriceMap/index.css';
// import { fetchBaiduMapApi } from '../../sources/common/mapUtil'
import LowPriceHeader from './LowPriceHeader';
import { UBT_KEY } from '../../constants/ubtKeys';

export default function LowPriceMapV2({ dCity }) {
	// const [mapLoaded, setMapLoaded] = useState(false);

	useEffect(() => {
		logTraceByKeyName(UBT_KEY.HOME.MAP_PRICE_SHOW, '');
		// fetchBaiduMapApi(() => {
		//   setMapLoaded(true);
		// })
	}, []);

	/* todo bfcache : @ctrip/flt-smart-component-pc/lowPriceMap getBaiduMapFetch */
	return (
		<LowPriceHeader
			dCity={dCity}
			channel="online_map"
			cityClickKey={UBT_KEY.HOME.MAP_CITY_CLICK}
			jumpMore={() => {
				logTraceByKeyName(UBT_KEY.HOME.MAP_MORE_CLICK, '');
			}}
			title="低价地图"
		>
			{(departCityCode) => <LowPriceMap departCityCode={departCityCode} />}
		</LowPriceHeader>
	);
}
