import MD5 from 'md5.js';
import moment from 'moment';
import { Dispatch } from 'redux';
import store from '../../../stores/channel';
import { getSwitchesFromGlobal } from '../../../actions/common';
import { CHANNEL_UBT_KEY_TYPES } from '../../../ubt.v2/channel';
import { SEARCH_BOX_UBT_KEY_TYPES } from '../../../ubt.v2/searchBox';
import { UbtHandler } from '../../../ubt.v2/handler';
import {
	// fetchHotDistricts,
	fetchCountryLowPrice,
	// fetPriceMapSwitch,
	genChangeArrivalDistrict,
	genSwitchToDistrictSearch,
} from '../../../actions/common/hotDistrict';
import { getPreAggSearchIdPromise } from '../../../sources/channel/getSearchId';
import { fetchFlightSameCityDetectData } from '../../../actions/common/searchBox';
import { getIfSwitchOpenAsync } from '../../../sources/common/getIfSwitchOpenAsync';
import {
	genCacheRecovery,
	genSelectFlightWay,
	genCleanOwRtError,
	genCleanMtError,
	genChangeDCityError,
	genShowHotCityPicker,
	genChangeACityError,
	genFocusMtSecondDCity,
	genChangeMtDCityError,
	genChangeMtACityError,
	genChangeDDateError,
	genChangeADateError,
	genChangeMtDDateError,
	genChangeClassGrade,
	genSetDirectFlight,
	genChangePaseengerCount,
	genChangeOwStartDate,
	genChangeOwEndDate,
	genChangeRtStartDate,
	genChangeRtEndDate,
	genExchangeCity,
	genCancelFocus,
	fetchLowPrice,
	fetchLowRPrice,
	genSetCanNotSearch,
	genResetCanNotSearch,
	genSelectPOIItem,
	genChangeMtStartDate,
	genAddMtSeg,
	genDelMtSeg,
	genExchangeMtSeg,
	genShowWarn,
	genCancelFocusMtSecondDCity,
	genSynSearchBoxDataToSearchMap,
	genUserLocation,
	genUserResident,
	fetchDomesticFlightsApi,
	genSearchLinkData,
} from '../../../actions/channel/channel';
import {
	currentFlightWaySelector,
	selectedCitySelector,
	canNotSearchSelector,
	rtStartDateSelector,
	owStartDateSelector,
	rtEndDateSelector,
	isDistrictSearchSelector,
	getMtSegListSelector,
	adtCountSelector,
	chiCountSelector,
	infCountSelector,
	arrivalDistrictSelector,
	focusReturnDateSelector,
	dDateErrorSelector,
	aDateErrorSelector,
	dCityErrorSelector,
	aCityErrorSelector,
	showValidateErrorTipSelector,
	isNeedFocusSecondSelector,
	showCityPickerSelector,
	focusNextInputSelector,
	lowPriceSelector,
	lowRPriceSelector,
	lowestPriceSelector,
	lowestRPriceSelector,
	workingDaysSelector,
	hotDistrictListSelector,
	countryLowestPriceSelector,
	countryLowPriceSelector,
	countryLowestRPriceSelector,
	countryLowRPriceSelector,
	mtErrorSelector,
	canAddMoreMtSegSelector,
	priceMapSwitchSelector,
	directflightSelector,
	domesticSelector,
	displayClassGradeSelector,
	displayPassengerSelector,
	domesticSearchPageSelector,
	getIfAppendSignToListPageSwitchSelector,
	getIfHomePreAggSearchSwitchSelector,
	changeTimeSelector,
	searchBoxOnlyDomesticSelector,
	changeChannelPageIdSwitchSelector,
	flightSameCityDataSelector,
} from '../baseSelector';

import { getIfMatchPreSearchPath, addSearchIdToCache } from '../../../sources/channel/preSearch';

const getProductToken = (dcity, acity, flightWay) => {
	const key1 = 'duew&^%';
	const key2 = "5d54nc'KH";
	return new MD5().update(dcity + acity + flightWay + key1 + key2).digest('hex');
};

const getDomesticCityAndAirportCode = (city) => {
	const isAirport = city.get('Type') == 3 || city.get('type') == 'airport';
	return {
		airportCode: isAirport ? city.get('Code') && city.get('Code').toLowerCase() : undefined,
		cityCode: isAirport
			? city.get('ParentCode') && city.get('ParentCode').toLowerCase()
			: city.get('Code') && city.get('Code').toLowerCase(),
		cityName: isAirport ? city.get('ParentName') : city.get('Name'),
	};
};
const fetchDomesticFlights = () => {
	const globalSwitches = getSwitchesFromGlobal();
	if (!globalSwitches.fetchDomesticFlightsSwitch) {
		return;
	}
	const state = store.getState();
	const owDDate = owStartDateSelector(state);
	const flightWay = currentFlightWaySelector(state);
	const selectedCity = selectedCitySelector(state);
	const domestic = domesticSelector(state);
	const changeTime = changeTimeSelector(state);
	if (flightWay == 'OW' && domestic && changeTime) {
		const owDCity = selectedCity.get('owDCity');
		const owACity = selectedCity.get('owACity');
		const dCity = owDCity && getDomesticCityAndAirportCode(owDCity);
		const aCity = owACity && getDomesticCityAndAirportCode(owACity);
		if (dCity.cityCode && aCity.cityCode && owDDate) {
			const dDate = owDDate.format('YYYY-MM-DD');
			const req = {
				airportParams: [
					{
						dcity: dCity.cityCode,
						acity: aCity.cityCode,
						dcityname: dCity.cityName,
						acityname: aCity.cityName,
						date: dDate,
						dport: dCity.airportCode,
						aport: aCity.airportCode,
					},
				],
				flightWay: 'Oneway',
				date: dDate,
				searchIndex: 1,
				token: getProductToken(dCity.cityCode, aCity.cityCode, 'Oneway'),
				from: 'indexPage',
				changeTime: changeTime,
			};
			fetchDomesticFlightsApi(req);
		}
	}
};


export const mapStateToProps = (state) => {
	return {
		flightWay: currentFlightWaySelector(state),
		selectedCity: selectedCitySelector(state),
		canNotSearch: canNotSearchSelector(state),
		rtDDate: rtStartDateSelector(state),
		owDDate: owStartDateSelector(state),
		rtADate: rtEndDateSelector(state),
		isDistrictSearch: isDistrictSearchSelector(state),
		getMtSegList: getMtSegListSelector(state),
		adtCount: adtCountSelector(state),
		chiCount: chiCountSelector(state),
		infCount: infCountSelector(state),
		classGrade: displayClassGradeSelector(state),
		passenger: displayPassengerSelector(state),
		arrivalDistrict: arrivalDistrictSelector(state),
		focusReturnDate: focusReturnDateSelector(state),
		dDateError: dDateErrorSelector(state),
		aDateError: aDateErrorSelector(state),
		dCityError: dCityErrorSelector(state),
		aCityError: aCityErrorSelector(state),
		showValidateErrorTip: showValidateErrorTipSelector(state),
		showOwAndRtValidateErrorTip: showValidateErrorTipSelector(state),
		isNeedFocusSecond: isNeedFocusSecondSelector(state),
		showCityPicker: showCityPickerSelector(state),
		focusNextInput: focusNextInputSelector(state),
		directflight: directflightSelector(state),
		changeTime: changeTimeSelector(state),
		lowPrice: lowPriceSelector(state),
		lowRPrice: lowRPriceSelector(state),
		lowestPrice: lowestPriceSelector(state),
		lowestRPrice: lowestRPriceSelector(state),
		flightSameCityData: flightSameCityDataSelector(state),

		// 精选地区
		workingDays: workingDaysSelector(state),
		hotDistrictList: hotDistrictListSelector(state),
		countryLowestPrice: countryLowestPriceSelector(state),
		countryLowPrice: countryLowPriceSelector(state),
		countryLowestRPrice: countryLowestRPriceSelector(state),
		countryLowRPrice: countryLowRPriceSelector(state),

		mtError: mtErrorSelector(state),
		canAddMoreMtSeg: canAddMoreMtSegSelector(state),
		priceMapSwitch: priceMapSwitchSelector(state),
		domestic: domesticSelector(state),
		domesticSearchPage: domesticSearchPageSelector(state),
		appendSignToListPageSwitch: getIfAppendSignToListPageSwitchSelector(state),
		homePreAggSearchSwitch: getIfHomePreAggSearchSwitchSelector(state),
		changeChannelPageIdSwitch: changeChannelPageIdSwitchSelector(state),
		searchBoxOnlyDomestic: searchBoxOnlyDomesticSelector(state),
	};
};

export const ubtSearchBox = function (ubtkey, searchBoxInstance) {
	UbtHandler.onUploadData(ubtkey, moment().format('YYYY-MM-DD HH:mm:ss:SSS'));

	// 传入了实例表示不是点击搜索按钮触发该方法
	if (searchBoxInstance && typeof searchBoxInstance.checkIfSearchIsValid === 'function') {
		const state = store.getState();
		const searchBoxOnlyDomestic = searchBoxOnlyDomesticSelector(state);
		const isDistrictSearch = isDistrictSearchSelector(state);

		getIfSwitchOpenAsync('channelSearchBoxPreSearchSwitch').then((opened) => {
			if (!opened) return;

			const { url: oldRedirectUrl } = searchBoxInstance.getRedirectUrl();

			const execPreSearch = (redirectUrl) => {
				// 进入路径匹配逻辑的条件： 1）搜索的不是国内  2）打开了首页预搜索开关   3）当前校验是有效的 4)不是搜索精选地区
				if (
					!searchBoxOnlyDomestic &&
					!isDistrictSearch &&
					!window.GlobalSwitches.noNeedSearchId &&
					searchBoxInstance.checkIfSearchIsValid()
				) {
					const matchedPath = getIfMatchPreSearchPath(ubtkey);
					// 能匹配到某个配置路径，就可以即刻发起 getSearchId 请求
					if (matchedPath) {
						// 打开新窗口是只有搜索国内酒店，能进入此处肯定排除了国内
						getPreAggSearchIdPromise(redirectUrl).then((searchId) => {
							if (searchId) {
								// 表示拿到了 searchid
								addSearchIdToCache(redirectUrl, searchId, matchedPath);
							}
						});
					}
				}
			};

			let unsubscribe = null;

			const timeoutPromise = new Promise((resolve) => setTimeout(() => resolve(oldRedirectUrl), 500));
			const urlChangedPromise = new Promise((resolve) => {
				// 此处需要订阅 store 的改变，因为调用该方法的时候 store 可能还没更新
				unsubscribe = store.subscribe(() => {
					const { url: newRedirectUrl } = searchBoxInstance.getRedirectUrl();
					if (oldRedirectUrl !== newRedirectUrl) {
						resolve(newRedirectUrl);
					}
				});
			});

			Promise.race([timeoutPromise, urlChangedPromise]).then((url) => {
				typeof unsubscribe === 'function' && unsubscribe();
				url && execPreSearch(url);
			});
		});
	}
};

export const ubtCalenderLowPrice = function (hasLowprice, isLowestPrice) {
	if (hasLowprice) {
		CHANNEL_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_ON_DATE_CAHNGE.value.process();
	}
	if (hasLowprice && isLowestPrice) {
		CHANNEL_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_ON_DATE_CAHNGE.value.process();
		CHANNEL_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_ON_LP_DATE_SELECT.value.process();
	}
};

export const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
	selectFlightWay(flightWay, params = {}) {
		dispatch(genSelectFlightWay(flightWay));

		if (params.userClick !== false) {
			if (flightWay == 'OW') {
				ubtSearchBox('presearch_single_radio', this);
			} else if (flightWay == 'RT') {
				ubtSearchBox('presearch_return_radio', this);
			}
		}
	},
	cacheRecovery(persist) {
		dispatch(genCacheRecovery(persist));
	},

	onCleanOwRtError() {
		dispatch(genCleanOwRtError());
	},
	onCleanMtError() {
		dispatch(genCleanMtError());
	},
	onChangeDCityError(error) {
		dispatch(genChangeDCityError(error));
	},
	openHotCityPicker(name) {
		dispatch(genShowHotCityPicker(name));
	},
	onChangeACityError(error) {
		dispatch(genChangeACityError(error));
	},
	onChangeDDateError(error) {
		dispatch(genChangeDDateError(error));
	},
	onChangeADateError(error) {
		dispatch(genChangeADateError(error));
	},
	focusMtSecondDCity() {
		dispatch(genFocusMtSecondDCity());
	},
	onChangeMtDCityError(index, error) {
		dispatch(genChangeMtDCityError(index, error));
	},
	onChangeMtACityError(index, error) {
		dispatch(genChangeMtACityError(index, error));
	},
	onChangeMtDDateError(index, error) {
		dispatch(genChangeMtDDateError(index, error));
	},
	// fetchHotDistricts() {
	// 	fetchHotDistricts(dispatch);
	// },
	onClassGradeChange(classGrade, domestic) {
		dispatch(genChangeClassGrade(classGrade, domestic));
	},
	setDirectFlight(checked) {
		dispatch(genSetDirectFlight(checked));
	},
	onSubmitPassengerTypeCount(passenger, domestic) {
		dispatch(genChangePaseengerCount(passenger, domestic));
	},
	onNextMomthClick() {
		CHANNEL_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_MONTH_NEXT_CLICK.value.process();
		SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK.value.process({
			tag: { type: 'calendarChangeMonth', text: '向前' },
		});
	},
	onPrevMomthClick() {
		CHANNEL_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_MONTH_PREV_CLICK.value.process();
		SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK.value.process({
			tag: { type: 'calendarChangeMonth', text: '向后' },
		});
	},

	onChangeOwStartDate(m, nextInput, isLowPrice, isLowestPrice) {
		SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_SELECT.value.process({
			tag: {
				type: 'selectDate',
				calendarType: 'depart',
				value: moment(m.date).format('YYYY-MM-DD'),
			},
		});
		ubtCalenderLowPrice(isLowPrice, isLowestPrice);
		dispatch(genChangeOwStartDate(moment(m.date), moment(m.date).format('YYYY-MM-DD')));
		fetchDomesticFlights();
		// dispatch(genSynSearchBoxDataToSearchMap({
		//     dDate: moment(m.date)
		// }))
		ubtSearchBox('presearch_single_date', this);
	},
	onChangeOwEndDate(m, nextInput, isLowPrice, isLowestPrice) {
		SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_SELECT.value.process({
			tag: {
				type: 'selectDate',
				calendarType: 'arrive',
				value: moment(m.date).format('YYYY-MM-DD'),
			},
		});
		dispatch(genChangeOwEndDate(moment(m.date), moment(m.date).format('YYYY-MM-DD')));
		dispatch(genSelectFlightWay('RT'));
		ubtCalenderLowPrice(isLowPrice, isLowestPrice);
		// dispatch(genSynSearchBoxDataToSearchMap({
		//     aDate: moment(m.date)
		// }))
		ubtSearchBox('presearch_round_date', this);
	},
	onChangeRtStartDate(m) {
		dispatch(genChangeRtStartDate(moment(m.date), moment(m.date).format('YYYY-MM-DD')));
		// dispatch(genSynSearchBoxDataToSearchMap({
		//     dDate: moment(m.date)
		// }))
		ubtSearchBox('presearch_single_date', this);
	},
	onChangeRtEndDate(m) {
		SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_SELECT.value.process({
			tag: {
				type: 'selectDate',
				calendarType: 'arrive',
				value: moment(m.date).format('YYYY-MM-DD'),
			},
		});
		dispatch(genChangeRtEndDate(moment(m.date), moment(m.date).format('YYYY-MM-DD')));
		// dispatch(genSynSearchBoxDataToSearchMap({
		//     aDate: moment(m.date)
		// }))
		ubtSearchBox('presearch_round_date', this);
	},

	exchangeCity() {
		dispatch(genExchangeCity());
		ubtSearchBox('presearch_switch_city', this);
	},

	clearFocusNextInput() {
		dispatch(genCancelFocus());
	},
	// 日历低价
	fetchLowPrice(dCityCode, aCityCode, options) {
		dispatch(fetchLowPrice(dCityCode, aCityCode, options));
	},
	// 返程低价日历
	fetchLowRPrice(flightSegmentList, cabin, flightWay, domestic) {
		dispatch(fetchLowRPrice(flightSegmentList, cabin, flightWay, domestic));
	},
	setCanNotSearch() {
		dispatch(genSetCanNotSearch());
	},
	resetCanNotSearch() {
		dispatch(genResetCanNotSearch());
	},

	clearPoiItem(name) {
		dispatch(genSelectPOIItem(name, null));
	},
	changeToDistrictSwitch(isDistrictSearch) {
		dispatch(genSwitchToDistrictSearch(isDistrictSearch));
	},
	changeArrivalDistrict(arrivalDistrict) {
		dispatch(genChangeArrivalDistrict(arrivalDistrict));
	},
	// onCityFocus() {
	// 	dispatch(fetPriceMapSwitch('priceMapSwitch'));
	// },

	fetchCountryLowPrice(dCityCode, aCountryCode, flightWay) {
		dispatch(fetchCountryLowPrice(dCityCode, aCountryCode, flightWay));
	},

	onChangeMtStartDate(index, m) {
		SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_SELECT.value.process({
			tag: {
				type: 'selectDate',
				calendarType: 'mtDepart',
				value: moment(m.date).format('YYYY-MM-DD'),
			},
		});
		dispatch(genChangeMtStartDate(index, moment(m.date), moment(m.date).format('YYYY-MM-DD')));
	},
	onAddMtSeg() {
		dispatch(genAddMtSeg());
	},
	onDelMtSeg(index) {
		dispatch(genDelMtSeg(+index));
	},
	exchangeMtCity(index) {
		dispatch(genExchangeMtSeg(+index));
	},
	setPoi(name, item, id, focusNextInput) {
		dispatch(genSelectPOIItem(name, item, id, focusNextInput));
		// if (name == "owDCity") {
		//     dispatch(genSynSearchBoxDataToSearchMap({
		//         dCity: item
		//     }))
		// }
	},

	showWarn() {
		dispatch(genShowWarn());
	},
	onDistrictError(error, dCityError) {
		dispatch(genChangeACityError(error));
		dispatch(genChangeDCityError(dCityError));
	},
	cancelFocusMtSecondDCity() {
		dispatch(genCancelFocusMtSecondDCity());
	},

	setArrivalDistrict(hotDistrict) {
		if (hotDistrict) {
			SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK.value.process({
				tag: {
					type: 'cityPickerSelect',
					value: 'country',
					selectCity: hotDistrict.areaName,
				},
			});
			dispatch(genChangeArrivalDistrict(hotDistrict));
			dispatch(genSwitchToDistrictSearch(true));
		}
	},
	onSynSearchBoxDataToSearchMap({ dDate, aDate, dCity, flightWay }) {
		dispatch(
			genSynSearchBoxDataToSearchMap({
				dDate: moment(dDate),
				aDate: moment(aDate),
				dCity,
				flightWay,
			})
		);
	},
	fetchUserLocation() {
		if (GlobalSwitches.defaultSearchValueSwitch) {
			dispatch(genUserResident());
		} else {
			dispatch(genUserLocation());
		}
	},
	fetchSearchLinkData(cityCode) {
		dispatch(genSearchLinkData(cityCode));
	},
	fetchFlightSameCityDetectData(source, cityCode) {
		dispatch(fetchFlightSameCityDetectData(source, cityCode));
	},
});
