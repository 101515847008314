import MD5 from 'md5.js'

const urlContainsSign = url => {
    if (url) {
        return url.indexOf('&sign=') > 0
    } else {
        return false
    }
}

const extractValidPart = url => {
    if (url) {
        const containsSign = urlContainsSign(url)
        if (containsSign) {
            return ''
        } else {
            const match = url.match(/.*?international\/search\/(.*)/i)
            if (match && match.length && match[1]) {
                return match[1]
            } else {
                return ''
            }
        }
    } else {
        return ''
    }
}

const genSign = str => {
    let sign = ''
    if (str) {
        sign = new MD5().update(str).digest('hex')
    }

    return sign
}

const trimSign = sign => {
    if (sign) {
        return sign.substring(0, 10)
    } else {
        return ''
    }
}

export const concatUrlQuery = (url, queryName, queryVal) => {
    if (url && queryName && queryVal) {
        return `${url}&${queryName}=${queryVal}`
    } else {
        return url || ''
    }
}

export const genSignFromUrl = url => {
    if (url) {
        const validPart = extractValidPart(url)

        if (validPart) {
            const sign = genSign(validPart),
                trimedSign = trimSign(sign)

            return trimedSign
        } else {
            return ''
        }
    } else {
        return ''
    }
}

export const appendSignToUrl = url => {
    if (url) {
        const sign = genSignFromUrl(url)
        if (sign) {
            const concatedUrl = concatUrlQuery(url, 'sign', sign)
            return concatedUrl
        } else {
            return ''
        }
    } else {
        return ''
    }
}