import { UbtHandler } from "../../ubt.v2/handler"
import { COMMON_CONFIGS } from '../../constants/channel/commonConfig'
import { getIfSwitchOpenAsync } from "../common/getIfSwitchOpenAsync"
import { getPreAggSearchIdPromise } from "./getSearchId"

const hasSentUbtKeyList = []
const matchedPathConfigList = []

const joinPath = path => {
    if (Array.isArray(path)) {
        return path.join('-')
    } else {
        return path
    }
}

export const uploadMatchedPreSearchPathUbt = joinedPath => {
    UbtHandler.onUploadData('c_match_home_pre_search_path', joinedPath)
}

export const getIfMatchPreSearchPath = key => {
    if (hasSentUbtKeyList.length > 50) {
        hasSentUbtKeyList.splice(0, hasSentUbtKeyList.length - 50)
    }

    hasSentUbtKeyList.push(key)
    const joinedHasSentUbtKeyList = joinPath(hasSentUbtKeyList)

    const matchedPathConfig = COMMON_CONFIGS.CHANNEL_SEARCH_BOX_PRE_SEARCH_PATH.find(configPathEntry => {
        const joinedConfigPathEntry = joinPath(configPathEntry)
        return joinedHasSentUbtKeyList.substring(joinedHasSentUbtKeyList.length - joinedConfigPathEntry.length, joinedHasSentUbtKeyList.length) === joinedConfigPathEntry
    })

    if (matchedPathConfig) {
        matchedPathConfigList.push(matchedPathConfig)
        uploadMatchedPreSearchPathUbt(joinPath(matchedPathConfig))
    }

    return matchedPathConfig
}

let inProgressGetPreSearchPromise = {}
let cachedSearchIds = {}
let totalCachedCount = 0
// 将拿到的 searchId 存到缓存
export const addSearchIdToCache = (url, searchId, path) => {
    if (url && searchId) {
        UbtHandler.onUploadData('c_home_pre_search_add_search_id', { url, searchId })
        totalCachedCount++
        cachedSearchIds[url] = {
            path,
            searchId,
            date: new Date()
        }
    }
}

// 是否包含搜索按钮
export const searchButtonInPreSearchPathConfig = COMMON_CONFIGS.CHANNEL_SEARCH_BOX_PRE_SEARCH_PATH.find(configPathEntry => {
    return configPathEntry && configPathEntry.length === 1 && configPathEntry[0] === 'presearch_inquire'
})

export const getPreSearchCachedTotalCount = () => totalCachedCount

// 获取已缓存的 searchId
export const getPreSearchCacheByUrl = (url, sendUbt) => {
    let searchIdCache = null
    let promiseCache = null

    if (url) {
        searchIdCache = cachedSearchIds[url]

        // 存在缓存，且未失效
        if (searchIdCache && searchIdCache.searchId) {
            const elapsed = (new Date() - searchIdCache.date) / 1000
            const timeout = COMMON_CONFIGS.CHANNEL_SEARCH_BOX_PRE_SEARCH_TIME_OUT_IN_SEC
            const valid = elapsed <= timeout * 1000
            if (valid) {
                sendUbt && UbtHandler.onUploadData('c_home_pre_search_read_cache', { url, cache: searchIdCache })
            } else {
                sendUbt && UbtHandler.onUploadData('c_home_pre_search_cache_timeout', { url, cache: searchIdCache, elapsed, timeout })
                searchIdCache = null
            }
        }
    }

    if (!searchIdCache) {
        promiseCache = inProgressGetPreSearchPromise[url]
    }

    return searchIdCache || promiseCache
}

export const searchButtonPreSearchConfigName = 'presearch_inquire'

export const uploadHitPreSearchUbt = (hit, path, count) => {
    UbtHandler.onUploadData('c_home_pre_search_hit_stat', { hit, path, totalCount: count })
}

export const forceGetPreSearchId = (url) => {
    let inProgressPromise = inProgressGetPreSearchPromise[url]
    if (inProgressPromise) {
        return inProgressPromise
    }

    let deleteInProgressPromise = (searchUrl) => delete inProgressGetPreSearchPromise[searchUrl]

    let getSearchIdPromise = new Promise((resolve, _reject) => {
        if (searchButtonInPreSearchPathConfig) {
            getIfSwitchOpenAsync('channelSearchBoxPreSearchSwitch')
                .then(opened => {
                    if (opened) {
                        uploadHitPreSearchUbt(true, [searchButtonPreSearchConfigName], 1 + getPreSearchCachedTotalCount())
                        UbtHandler.onUploadData('presearch_on_mouse_over_search_btn', {})

                        getPreAggSearchIdPromise(url)
                            .then(searchId => {
                                UbtHandler.onUploadData('get_search_id_mouseover_serch_button', searchId)
                                deleteInProgressPromise(url)
                                resolve(searchId)

                                addSearchIdToCache(url, searchId, searchButtonPreSearchConfigName)
                            })
                            .catch(err => {
                                UbtHandler.onUploadData('c_pre_search_on_mouseover_serch_button_err', err)
                                deleteInProgressPromise(url)
                            })
                        uploadMatchedPreSearchPathUbt([searchButtonPreSearchConfigName])
                    } else {
                        uploadHitPreSearchUbt(false, [], getPreSearchCachedTotalCount())
                        resolve('')
                    }
                })
        } else {
            deleteInProgressPromise(url)
            uploadHitPreSearchUbt(false, [], getPreSearchCachedTotalCount())
            UbtHandler.onUploadData('c_home_pre_search_inquire_not_in_config', { remark: '首页hover搜索按钮，预搜索没有匹配到任何路径，且搜索按钮路径 presearch_inquire 没有在配置内' })
            resolve('')
        }
    })

    let timeoutPromise = new Promise((resolve) => {
        setTimeout(() => {
            deleteInProgressPromise(url)
            resolve('')
        }, 50)
    })

    let promise = Promise.race([getSearchIdPromise, timeoutPromise])

    inProgressGetPreSearchPromise[url] = promise

    return promise
}
