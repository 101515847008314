import React, { useEffect, useMemo, useState } from 'react';
import { logTraceByKeyName } from '@ctrip/xproduct-library-h5/lib/Tracking';
import UrlPools from '@ctrip/flt-smart-component-pc/urlPools';
import { CHANNEL_UBT_KEY_TYPES } from '../../ubt.v2/channel';
import City from '../../components/Base/searchBox/City';

export default function LowPriceHeader(props) {
	const { dCity, children, title, jumpMore, channel, cityClickKey } = props;

	const [cityFocus, setCityFocus] = useState(false);
	const [nextDCity, setNextDCity] = useState('');

	useEffect(() => {
		if (dCity) {
			let nextDCity = dCity.toJS();
			if (nextDCity && nextDCity.ParentCode && nextDCity.type === 'airport') {
				nextDCity = {
					...nextDCity,
					Code: nextDCity.ParentCode,
				};
			}
			setNextDCity(nextDCity);
		}
	}, [dCity]);

	function onCityFocus() {
		setCityFocus(true);
	}

	function onCityBlur() {
		setCityFocus(false);
		logTraceByKeyName(cityClickKey, '');
	}

	function changePoi(name, data) {
		if (data) {
			// 非城市数据转换为城市数据
			if (data.Name != nextDCity.Name) {
				CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK.value.process({
					tag: { type: 'lowPriceReportCityChange', data: data },
				});
			}
			if (data.type != 'city' && data.ParentCode && data.ParentName && data.ParentCityId) {
				data = {
					Code: data.ParentCode,
					Name: data.ParentName,
					CityId: data.ParentCityId,
				};
			}
			setNextDCity(data);
		}
	}
	const cityText = useMemo(() => {
		if (!dCity) return '上海';
		const { Name = '' } = dCity;
		const indexOfOther = Name.indexOf('(');
		if (indexOfOther >= 0) {
			return Name.substr(0, indexOfOther);
		}
		return Name;
	}, [dCity]);

	return (
		<div className="low-price-reprot-container low-price-reprot-container-v2">
			<div className="search-box">
				<div>
					<h1 className="low-price-reprot-title-v2">{title}</h1>
					<span>出发地：</span>
					<div className="city-filter-container">
						<City
							key={1}
							mode="D"
							label="请输入出发地"
							name={'owDCity'}
							errorMsg={''}
							cityText={cityText}
							id="search"
							selectorStyle={{ position: 'absolute', left: 0, top: 42 }}
							handleEnter={() => {}}
							changePoi={changePoi}
							inputClass={'form-input-v3'}
							onInputFocus={onCityFocus}
							onInputBlur={onCityBlur}
							needTimeZone={false}
							addon={
								cityFocus ? (
									<i className="iconf-arrow-up">&#xe604;</i>
								) : (
									<i className="iconf-arrow-down">&#xe60c;</i>
								)
							}
						/>
					</div>
				</div>
				<a
					href={UrlPools.getFuzzySearch({
						dCity: nextDCity && nextDCity.Code,
						dCityName: nextDCity && nextDCity.Name,
						channel: channel || 'home',
					})}
					onClick={() => {
						jumpMore && jumpMore();
					}}
				>
					<span>更多目的地</span>
					<i className="i-right iconf-check">&#xe600;</i>
				</a>
			</div>
			{children(nextDCity && nextDCity.Code)}
		</div>
	);
}
