import React from 'react';
import dynamic from 'next/dynamic';
import Skeleton from '@/src/components/loading/channel';
import { ubtError } from '@/src/sources/common/lightUbt';

export default dynamic(() => import(/* webpackChunkName: "channel.domesticSEOContainer" */ './DomesticSEOContainer'), {
	ssr: false,
	loading: ({ error }) => {
		if (error) {
			ubtError(error);
		}
		return <Skeleton />;
	},
});
