import { fetchGet } from "../../actions/common"
import URL from '../../constants/channel/api'
import { trimUrlHost } from '../common/trimUrlHost'
import { genSignFromUrl, concatUrlQuery } from "../../utils/appendSignToUrl"

export const getPreAggSearchId = url => {
    // oneway-bjs-syd?depdate=2023-10-20&cabin=y_s&adult=1&child=0&infant=0&sign=95f5448df7&v=0.32834655410719593
    return new Promise((resolve, reject) => {
        fetchGet(`${URL.getPreAggSearchId}/${url}`)
            .then(json => json ? json.data : null)
            .then(data => resolve(data))
            .catch(err => reject(err))
    })
}

export const getPreAggSearchIdPromise = (redirectUrl) => {
    const trimedUrl = trimUrlHost(redirectUrl)
    const sign = genSignFromUrl(`${URL.getPreAggSearchId}/${trimedUrl}`)
    if (sign && trimedUrl) {
        return new Promise((resolve) => {
            const urlWithSign = concatUrlQuery(trimedUrl, 'sign', sign)
            getPreAggSearchId(urlWithSign)
                .then(data => {
                    if (data) {
                        const { searchId } = data.data || {}
                        resolve(searchId || '')
                    } else {
                        resolve('')
                    }
                }).catch(err => {
                    console.error(err)
                    resolve('')
                })
        })
    } else {
        return Promise.resolve('')
    }
}
