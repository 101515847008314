import store from '../../stores/channel'
import { genOnMouseDown, genOnMouseUp, genOnResize } from '../../actions/channel/channel'

/**
 * 载入页面后的窗口事件绑定
 */
export default class EventBinder {
    static init() {
        let handleMouseDown = (e) => {
            store.dispatch(genOnMouseDown(e))
        }

        let handleMouseUp = (e) => {
            store.dispatch(genOnMouseUp(e))
        }

        let handleResize = () => {
            store.dispatch(genOnResize())
        }

        handleResize()

        window.addEventListener('mousedown', handleMouseDown, false)
        window.addEventListener('mouseup', handleMouseUp, false)
        window.addEventListener('resize', handleResize, false)
    }
}
