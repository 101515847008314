import React, { useEffect } from 'react'
import { UbtHandler } from '../../ubt.v2/handler'
import { getSwitchesFromGlobal } from '../../actions/common'
import announcementsConfig from '../../constants/channel/announcements'

const globalSwitches = getSwitchesFromGlobal()

const channelAnnouncement = announcementsConfig.find(
    announcement => announcement.name === 'channelAnnouncement'
)

const jumpDetail = () => {
    if (channelAnnouncement.detailLink) {
        UbtHandler.onUploadData('c_announcement_click_detail', { text: '国际频道首页跳转到公告详情页' })
        window.open(channelAnnouncement.detailLink)
    }
}

const Announcement = () => {
    if (!channelAnnouncement || !globalSwitches[channelAnnouncement.switchKey]) return null

    useEffect(() => {
        UbtHandler.onUploadData('c_announcement_display', { text: '国际频道首页展示公告' })
    }, [])

    return (
        <div className='announcement_block'>
            <span className='iconf-warn'>&#xe62c;</span>
            <div className='title'>重要公告:</div>
            <div className='content'>{channelAnnouncement.content}</div>
            {channelAnnouncement.detailLink && (
                <div className='detail-link' onClick={jumpDetail}>
                    详情
                </div>
            )}
        </div>
    )
}

export default Announcement
