import mergeConfig from "../common/mergeConfig"
import Q_CONFIG_KEYS from "./qConfigKeys"

const _COMMON_CONFIGS = {
    preLoadListPageUrl: 'international/search/oneway-jkl-hkg?depdate=2020-11-01&cabin=y_s&adult=1&child=0&infant=0',
    CHANNEL_SEARCH_BOX_PRE_SEARCH_PATH: [
        ['presearch_inquire'],
        ['presearch_arrive_city'],
        ['presearch_depart_city', 'presearch_arrive_city', 'presearch_single_date'],
        ['presearch_single_date'],
        ['presearch_arrive_city', 'presearch_single_date', 'presearch_round_date'],
        ['presearch_single_date', 'presearch_round_date'],
        ['presearch_depart_city', 'presearch_arrive_city', 'presearch_single_date', 'presearch_round_date'],
        ['presearch_arrive_city', 'presearch_single_date'],
        ['presearch_depart_city'],
        ['presearch_single_radio', 'presearch_depart_city', 'presearch_arrive_city', 'presearch_single_date']
    ],
    CHANNEL_SEARCH_BOX_PRE_SEARCH_TIME_OUT_IN_SEC: 200,
    SPA_TIMEOUT_IN_MS: 1500
}

export const COMMON_CONFIGS = mergeConfig(Q_CONFIG_KEYS.COMMON_CONFIG, _COMMON_CONFIGS)
